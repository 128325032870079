import { Component } from 'react';

import { IOC_TYPES } from '../../context/ioc.types';
import { lazyInject } from '../../context/module';
import { LoginService } from '../../services/login.service';
import { ScriptLoadService } from '../../services/script-load.service';
import { UIContext } from '../../services/ui-context';
import { BrowserUtils } from '../../ui-utils/browser-utils';
import { UIUtils } from '../../utils/dom-utils';
import { AccountDetails } from '../../utils/request.utils';
import { untilDestroyed } from '../../utils/until-destroyed';
import ContactUsModal from '../ui/contact-us-modal/contact-us.modal';
import { LoginModalComponent } from '../ui/login/login-modal.component';
import { LoginComponentProps } from '../ui/login/login.component';
import styles from './subheader.component.module.scss';
import Link from 'next/link';

interface SubheaderProps {
    isOpen: boolean;
    onToggle: (state) => void;
}

interface HeaderState {
    openState: boolean;
}

export class SubheaderComponent extends Component<SubheaderProps, HeaderState> {

    static getDerivedStateFromProps(props: SubheaderProps, state: HeaderState): HeaderState {
        return {
            openState: props.isOpen,
        };
    }

    isUserLoggedIn = false;
    @lazyInject(IOC_TYPES.LoginService) private loginService: LoginService;
    @lazyInject(IOC_TYPES.UIContext) private uiContext: UIContext;
    @lazyInject(IOC_TYPES.ScriptLoadService) private scriptLoadService: ScriptLoadService;

    windowClickFn;

    readonly state: Readonly<HeaderState> = {
        openState: this.props.isOpen,
    };
    constructor(props: SubheaderProps) {
        super(props);
        this.isUserLoggedIn = this.loginService.isUserLoggedIn;
        this.loginService.onUserDetailsChanged.pipe(
            untilDestroyed(this),
        ).subscribe({
            next: (accountDetails: AccountDetails) => {
                this.isUserLoggedIn = this.loginService.isUserLoggedIn;
            }
        });
    }

    componentDidMount() {
        if (BrowserUtils.hasWindow()) {
            this.windowClickFn = (e) => {
                if (this.state.openState && !UIUtils.hasParentWithCSSSelector(e.target, `.${styles.subheader}`)
                    && !UIUtils.hasParentWithCSSSelector(e.target, `.submenu-trigger`)) {
                    this.toggleMenu();
                }
            }
            window.addEventListener('click', this.windowClickFn);
        }
    }

    componentWillUnmount() {
        if (BrowserUtils.hasWindow()) {
            window.removeEventListener('click', this.windowClickFn);
        }
    }

    closeMenu() {
        // if (this.state.openState) {
            this.setState({
                openState: false,
            });
            this.props.onToggle(false);
        // }
    }

    toggleMenu() {
        const newState = !this.state.openState;
        this.setState({
            openState: newState,
        });
        this.props.onToggle(newState);
    }

    private showLogin(e: MouseEvent) {
        e.preventDefault();
        this.closeMenu();
        const initialState: LoginComponentProps = {
            mode: 'login',
            registerEnabled: true,
            passwordResetEnabled: true,
            submitBtnText: 'Submit',
            onSuccess: () => {
                this.forceUpdate();
            },
        };
        LoginModalComponent.show(initialState);
    }

    private logout(e) {
        e.preventDefault();
        this.closeMenu();
        this.loginService.logout().subscribe(
            () => {
                this.uiContext.successToast('You have been successfully logged out!');
            },
        );
    }

    private async showContactUs(e: MouseEvent) {
        e.preventDefault();
        this.closeMenu();
        await this.scriptLoadService.loadAllScriptsIfNotLoaded();
        ContactUsModal.show({});
    }

    render() {
        return <div className={`${styles['subheader']}`}>
            <div className={`${this.state.openState ? styles.opened : styles.closed}`}>
                {
                    !this.isUserLoggedIn && <div className={styles['nav-item']}>
                        <div className={`d-block cursor-pointer ${styles['link']}`} onClick={($event) => this.showLogin($event as any)}>
                            Login
                        </div>
                    </div>
                }

                <div className={styles['nav-item']}>
                    <Link href="/portal" className="d-block cursor-pointer" onClick={($event) => this.closeMenu()}>
                        User Portal
                    </Link>
                </div>
                {
                    this.isUserLoggedIn && <div className={styles['nav-item']}>
                        <div className={`d-block cursor-pointer ${styles['link']}`} onClick={($event) => this.logout($event)}>
                            Logout
                        </div>
                    </div>
                }

                <div className={styles['nav-divider']}>&nbsp;</div>
                <div className={styles['nav-item']}>
                    <Link href="/product" className="d-block cursor-pointer" onClick={($event) => this.closeMenu()}>
                        Pricing
                    </Link>
                </div>
                <div className={styles['nav-item']}>
                    <div className={`d-block cursor-pointer ${styles['link']}`} onClick={($event) => this.showContactUs($event as any)}>
                        Contact
                    </div>
                </div>
            </div>
        </div>
    }
}