import Link from 'next/link';
import Router from 'next/router';
import { Component, Fragment } from 'react';

import { Subscription } from 'rxjs';
import { IOC_TYPES } from '../../context/ioc.types';
import { lazyInject } from '../../context/module';
import { FileUpload } from '../../models/file-upload';
import { EnvironmentService } from '../../services/environment.service';
import { FileManagerService } from '../../services/file-manager.service';
import { IconHelper } from '../../utils/icon-helper';
import { HtmlEntityTypes } from '../../utils/icon.utils';
import { RxUtils } from '../../utils/rx-utils';
import style from './header.component.module.scss';
import { SubheaderComponent } from './subheader.component';

interface HeaderState {
    openState: boolean;
    isSubheaderOpen: boolean;
    downloadableFileCount: number;
}

export default class HeaderComponent extends Component<{}, HeaderState> {

    @lazyInject(IOC_TYPES.EnvironmentService) envService: EnvironmentService;
    @lazyInject(IOC_TYPES.FileManagerService) fileManagerService: FileManagerService;

    private subscriptions: Subscription[] = [];

    readonly state: Readonly<HeaderState> = {
        openState: false,
        isSubheaderOpen: false,
        downloadableFileCount: 0,
    }
    constructor(props: any) {
        super(props);
    }

    componentDidMount(): void {
        var sub = this.fileManagerService.fileUploadsListChanged.subscribe({
            next: (fileUploads: FileUpload[]) => {
                this.updateDownloadableFileCount(fileUploads);
            },
            error: err => {
            },
        });
        this.subscriptions.push(sub);

        var sub2 = this.fileManagerService.fileUploadsListChanged.subscribe({
            next: (fileUploads: FileUpload[]) => {
                this.updateDownloadableFileCount(fileUploads);
            },
            error: err => {
            },
        });
        this.subscriptions.push(sub2);
    }

    componentWillUnmount() {
        RxUtils.safeCloseSubscribers(this.subscriptions.splice(0, this.subscriptions.length));
    }

    private updateDownloadableFileCount(fileUploads: FileUpload[]) {
        this.setState({ downloadableFileCount: fileUploads.length });
    }

    toggleMenu() {
        this.setState({
            openState: !this.state.openState,
        });
    }

    closeMenu() {
        if (this.state.openState) {
            this.setState({
                openState: false,
            });
        }
    }

    navigateToHome() {
        // const router = Router.push.useRouter();
        Router.push('/');
    }

    toggleSubheader() {
        this.setState({ isSubheaderOpen: !this.state.isSubheaderOpen });
    }

    subheaderToggled(state) {
        this.setState({ isSubheaderOpen: state, openState: state });
    }

    render() {
        return (
            <header>
                <div className={`${style.topnav} ${this.state.openState ? style.responsive : ''}`}>
                    <Link href="/" className={`${style.title} ${style.link}`}>
                            <img width={'38px'} height={'38px'}  src="/img/xconvert/logo2.svg" alt={`${this.envService.env.siteDisplayName} logo`} />
                            <span style={{fontSize: "2rem"}}>XConvert</span>
                    </Link>
                    <Link href="/downloads" className={`${style.link}`} onClick={() => this.closeMenu()}>
                        <span>Downloads</span>&nbsp;
                        <span className={style['download-count']}>{this.state.downloadableFileCount}</span>
                    </Link>
                    {
                        this.envService.isXConvert() && <span key={"header-far-right"}
                            className={`${style.link} ${style.icon}`}
                            onClick={(e) => this.toggleMenu()}>
                            &#9776;
                        </span>
                    }
                    {
                        this.envService.isXConvert() && <span
                            key={"header-right"}
                            className={style['links-container']}>
                        <>
                            <Link href="/compress-mp4" className={`${style.link}`}
                                onClick={e => this.closeMenu()}>
                                Compress MP4
                            </Link>
                            <Link href="/video-compressor" className={`${style.link}`}
                                onClick={e => this.closeMenu()}>
                                Video Compressor
                            </Link>
                            <Link href="/compress-jpeg" className={`${style.link}`}
                                onClick={e => this.closeMenu()}>
                                Compress JPEG
                            </Link>
                            <Link href="/merge-image-to-pdf" className={`${style.link}`}
                                onClick={e => this.closeMenu()}>
                                Merge Image To PDF
                            </Link>
                            <Link href="/product" className={`${style.link} ${style['pro-btn']}`}
                                onClick={e => this.closeMenu()}>
                                <button className={`btn btn-primary btn-sm ${style['btn-xs']}`}>Get Pro!</button>
                            </Link>
                            <span
                                className={`${style.link} ${style['submenu-trigger']} submenu-trigger`}
                                onClick={(e) => this.toggleSubheader()}>
                                {
                                    IconHelper.getIconElement(HtmlEntityTypes.TRICOLON)
                                }
                            </span>
                            <SubheaderComponent isOpen={this.state.isSubheaderOpen} onToggle={(state) => this.subheaderToggled(state)}></SubheaderComponent>
                            </>
                         </span>
                    }     
                </div>
            </header>
        );
    }
};
